  export const EventsEsummit = [
  {
    title: "STARTUP MELA",
    tagline:  "27-28 JAN 2024",
    image: "/images/START UP MELA.jpg",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfcii4AmZ494oxaNll1JTfJ230Rhn_NntZ3VgQM3p1weZgN5A/viewform?usp=sf_link",
    desc:  "This is our mega event where participants must prepare a pitch deck of their Business Idea and present it to a panel of renowned investors. This helps participants grasp various elements of business planning from ideation to go-to-market strategies and gain funding."
  },
  {
    title: "BGMI",
    tagline:  "27 JAN 2024",
    image: "/images/BGMI.jpg",
    link: "./BGMI",
    desc:  "The Esports industry which is worth 2.5 billion USD is a whole new avenue of entertainment in the 21st century. Physical sports light up emotions and qualities like sportsmanship, leadership, communication, and teamwork. "
  },
  {
    title: "BIT THON",
    tagline:  "28 JAN 2024",
    image: "/images/BIT THON.jpg",
    link: "./BIT_THON",
    desc:  "This competition is designed to empower tech enthusiasts, developers, and innovators to create tangible solutions that address the pressing problems of BIT MESRA. "
  },
  {
    title: "IPL AUCTION",
    tagline:  "26 JAN 2024",
    image: "/images/IPL AUCTION.jpg",
    link: "./IPL_AUCTION",
    desc:  "This is one of our most popular events where we do a simulation of an actual IPL auction. It tests the participants on both knowledge about the game as well as about the strategies to keep in mind in an auction. "
  },
  {
    title: "QUIZ TRIVIA",
    tagline:  "28 JAN 2024",
    image: "/images/QUIZ TRIVIA.jpg",
    link: " ./QUIZ-TRIVIA",
    desc:  " The Trivia Quiz Competition is a battle of the minds, where participants can showcase their general knowledge and business acumen. It's a fast-paced and entertaining event designed to challenge your thinking and keep you on your toes. "

  },
  {
    title: "REVERSE PITCHING ",
    tagline: "27 JAN 2024",
    image: "/images/REVERSE PITCHING.jpg",
    link: "./REVERSE_PITCHING",
    desc:  " In the dynamic world of start-ups and entrepreneurship, it's not just about the business idea; it's also about the person behind it. The Reverse Pitching Competition challenges participants to step into the spotlight and present themselves as the driving force behind their innovative concepts."
   },
    {
    title: "STOCK STOICISM",
    tagline:  "27 JAN 2024",
    image: "/images/STOCK STOICISM.jpg",
    link: "./STOCK_STOICISM",
    desc:  "The event requires the participants to invest money in multiple stocks from a fixed amount of virtual money given to them initially and make a detailed report on all the factors considered for their investments. The winner is judged based on the explanation and report given by participants for each investment and also keeping the present scenarios at the macro level affecting the business model. It will help students understand various business models and the correlation between various events in the practical world."
  },
  {
    title: "ANCIENT ENTREPRENEUR",
    tagline:  "27 JAN 2024",
    image: "/images/ANCIENT ENTREPRENEUR.jpg",
    link: "./ANCIENT_ENTREPRENEUR",
    desc: "Since timing is everything in the field of Entrepreneurship, this event's heart will be based on timing. The event will test the participant's ability to link the product and the best time to introduce the product and to put up valid points to justify the answer."
  },

  ];
