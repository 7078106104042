import React from "react";
import styled from "styled-components";
import Countdown from "../Countdown";
import logo from "./6-BIT-logo.png";
const TopSectionContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: #1756dd32;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 13%;
	z-index: 99;
`;

const Logo = styled.h1`
	margin: 0;
	color: #fff;
	font-weight: 800;
	font-size: 80px;
`;

const Slogan = styled.h4`
	margin: 0;
	color: #fff;
	font-weight: 700;
	font-size: 30px;
	margin-top: 10px;
`;

const Paragraph = styled.p`
	margin: 0;
	margin-top: 3em;
	color: #fff;
	font-size: 14px;
	line-height: 1.5;
	font-weight: 500;
	max-width: 30%;
	text-align: center;
`;

const DonateButton = styled.button`
	outline: none;
	border: none;
	background-color: #27b927;
	color: #fff;
	font-size: 16px;
	font-weight: 700;
	border-radius: 8px;
	padding: 8px 2em;
	margin-top: 3em;
	cursor: pointer;
	border: 2px solid transparent;
	transition: all 350ms ease-in-out;

	&:hover {
		background-color: transparent;
		border: 2px solid #27b927;
	}
`;

const MadeBy = styled.h3`
	color: #fff;
	position: fixed;
	bottom: 5px;
	left: 50%;
	transform: translateX(-50%);
`;

export function TopSection() {
	return (
		<div>
			<div className="absolute top-8 w-full h-full flex items-center justify-center text-white">
				<div className="w-full md:w-[90%] lg:w-[80%] xl:w-[70%] 2xl:w-[60%] mx-auto p-4 text-center">
					<div className="flex flex-row align-middle justify-center ">
						<img
							src={logo}
							className=" md:h-25 h-20 p-2 flex items-center bg-none"
							alt=""
						/>

						<h1 className="text-2xl md:text-3xl md:font-bold md:p-3">Birla Institute Of Technology
							<h2 className=" text-xs md:text-base justify-self-end " >Mesra Ranchi(India) - 835215</h2>

						</h1>
					</div>
					<h1 className="text-transparent font-bold md:font-black bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400  text-4xl md:text-7xl  scale-120">
						E-SUMMIT 2024
					</h1>

					<p className=" align-middlemax-w-[600px] drop-shadow-2xl  text-xl">
						January 26-28, 2024
					</p>
					<div className="mt-[-1] "><Countdown/></div>
				</div>
				
			</div>
        
		</div>
	);
}



